<template>
  <div class="contact">
    <b-img
      :src="require('../../assets/contact_banner.jpg')"
      fluid-grow
      alt="Responsive image"
    ></b-img>
    <div class="contact_box">
      <b-container
        style="border-bottom: 1px solid #e5e5e5; padding: 0 0 20px 0"
        class="bv-example-row"
        fluid
      >
        <b-row>
          <!-- <b-col class="wow fadeInUp" lg="6" md="6" sm="12" xs="auto"
            ><b-card
              title="电话"
              :img-src="require('../../assets/ico-phone.png')"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="p"
              class="mb-4 card_li"
            >
              <b-card-text style="color: #333; font-size: 20px">
                400-138-8626
              </b-card-text>
            </b-card></b-col
          > -->
          <b-col class="wow fadeInUp" lg="12" md="12" sm="12" xs="auto"
            ><b-card
              title="邮箱"
              :img-src="require('../../assets/ico-email.png')"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="p"
              class="mb-4 card_li"
            >
              <b-card-text style="color: #333; font-size: 20px">
                dennie0128@126.com
              </b-card-text>
            </b-card></b-col
          >
        </b-row>
      </b-container>
    </div>
    <div class="contact_map_box">
      <b-container style="padding: 0 0 60px 0" class="bv-example-row" fluid>
        <b-row>
          <b-col class="wow fadeInUp" lg="6" md="6" sm="12" xs="12">
            <b-img
              :src="require('../../assets/map.png')"
              fluid
              alt="Responsive image"
            ></b-img>
          </b-col>
          <b-col class="wow fadeInUp" lg="6" md="6" sm="12" xs="12">
            <div class="address">
              <b-card
                style="border: 0; background: transparent"
                title="小鲲智能致力推动环保行业物联创新"
                img-alt="Image"
                img-bottom
                title-tag="p"
              >
                <b-card-text style="font-size: 14px; color: #333333">
                  地址：广州市番禺区东环街迎宾路730号番禺节能科技园天安科技产业大厦2座207
                </b-card-text>
                <b-img
                  :src="require('../../assets/erweima.png')"
                  fluid
                  alt="Responsive image"
                ></b-img>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- <Footers /> -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import Footers from "../../components/footers.vue";
export default {
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "联系我们",
      meta: [
        {
          name: "keywords",
          content: "小鲲智能,小鲲智能技术(广州)有限公司,小鲲智能技术广州有限公司,联系我们",
        },
        {
          name: "description",
          content:
            "小鲲智能致力推动环保行业物联创新，联系电话:400-138-8626，地址：广州市番禺区东环街迎宾路730号番禺节能科技园天安科技产业大厦2座207",
        },
      ],
    };
  },
  // components: {
  //   Footers,
  // },
  created() {
    scrollTo(0, 0);
  },
  mounted() {
    new WOW({ live: false }).init();
  },
};
</script>
<style lang="less" scoped>
.contact {
  width: 100%;
  height: auto;
  .contact_box,
  .contact_map_box {
    width: 100%;
    height: auto;
    background-color: #fff;
    margin: 0 auto;
    padding: 60px 120px 0px 120px;
    .card_li {
      cursor: pointer;
      border: 0;
    }
    .card-body {
      padding: 10px;
      box-sizing: border-box;
      text-align: center;
      .card-title {
        margin: 10px 0 !important;
        color: #888888;
        font-size: 14px;
      }
    }
  }
  .address {
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    .card-body {
      .card-title {
        color: #222222;
        font-size: 24px;
        margin: 20px 0 !important;
      }
      .card-text {
        margin-bottom: 10px !important;
      }
    }
  }
  @media screen and (min-width: 375px) and (max-width: 992px) {
    .contact_box,
    .contact_map_box {
      padding: 50px 20px 0px 20px;
    }
    .address {
      width: 100%;
      height: 100%;
      background-color: #f7f7f7;
      .card-body {
        .card-title {
          color: #222222;
          font-size: 20px;
          margin: 20px 0 !important;
        }
        .card-text {
          margin-bottom: 10px !important;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1366px) {
    .contact_box,
    .contact_map_box {
      padding: 50px 20px 0px 20px;
    }
  }
  @media screen and (min-width: 1366px) and (max-width: 1550px) {
    .contact_box,
    .contact_map_box {
      padding: 50px 20px 0px 20px;
    }
  }
  @media screen and (min-width: 1550px) and (max-width: 1920px) {
    .contact_box,
    .contact_map_box {
      padding: 60px 120px 0px 120px;
    }
  }
  @media screen and (max-width: 375px) {
    .contact_box,
    .contact_map_box {
      padding: 40px 0px 0px 0px;
    }
    
  }
}
</style>